import React from 'react';
import logo from '../../images/az.jpg'; // with import
import './about.scss';

export default class About extends React.Component {
    render() {
        return (
            <div className="wrapper about_page">
                <img src={logo} />

                <p>Oleksii Zinchenko was born on&nbsp;October&nbsp;8, 1985 in&nbsp;Kyiv, Ukraine. He&nbsp;studied at&nbsp;school &#8470;&nbsp;26&nbsp;and graduated from the Kyiv National University of&nbsp;Technology and Design.</p>

                <p>He&nbsp;often focuses on&nbsp;people of&nbsp;different nationalities and identities, architectural objects and their artistic interpretations, modern forms of&nbsp;communication and rethinking of&nbsp;existing art works in&nbsp;his research.</p>

                <div className="about_page-list">
                    <p>Group exhibitions</p>

                    <ul>
                        <li>Photo festival, Krak&oacute;w Photo Fringe (Krak&oacute;w, Poland) 2017&nbsp;&mdash; &laquo;Air. Reflection. Soar&raquo;</li>
                        <li>Photo festival, Krak&oacute;w Photo Fringe (Krak&oacute;w, Poland) 2018&nbsp;&mdash; &laquo;Roma are&nbsp;us&raquo;</li>
                        <li>Earthmate eco festival (Kyiv, Ukraine) 2020&nbsp;&mdash; &laquo;Earth.&raquo;</li>
                    </ul>
                </div>

                <div className="about_page-list">
                    <p>Solo exhibitions</p>
                    <ul>
                        <li>Museum of&nbsp;Kyiv history (Kyiv, Ukraine) 2018&nbsp;&mdash; &laquo;Roma are&nbsp;us&raquo;</li>
                        <li>Y&nbsp;Gallery (Minsk, Belarus) 2019&nbsp;&mdash; &laquo;Roma are&nbsp;us&raquo;</li>
                    </ul>
                </div>
            </div>
        )
    }
}