import React from 'react';
import './right-column.scss';

const RightColumn = (props) => {
    const {seriesDescription, showSeries, seriesNav } = props;

    return (
        <div className="wrapper single_series_page right_column">
	 		<div className="single_series-content">
	 			<div className="text">
					<div className="sticky" dangerouslySetInnerHTML={{__html: seriesDescription}}></div>
				 </div>
	 			<div className="gallery">
	 				{ showSeries }
	 			</div>
	 		</div>

	 		<div className="single_series-nav">
	 			{ seriesNav }
	 		</div>
	 	</div>
    )
}

export default RightColumn;