import React, {useState, useEffect} from 'react';
import './two-columns.scss';



const TwoColumns = (props) => {
    const {seriesDescription, showSeries, seriesNav } = props;
    const [width, setWidth] = useState(window.innerWidth);
    const updateWidth = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    });

    if (window.innerWidth <= 768) {
        return (
            <div className="wrapper single_series_page two_columns">
                 <div className="single_series-content">
                     <div className="gallery">
                        <div className="gallery_item text" dangerouslySetInnerHTML={{__html: seriesDescription}}></div>
                        {showSeries}
                     </div>
                 </div>
    
                 <div className="single_series-nav">
                     { seriesNav }
                 </div>
             </div>
        )
    } else {
        let odd = [];
        let even = [];

        showSeries.map((el, i) => i % 2 !== 0 ? odd.push(el) : even.push(el));

        return (
            <div className="wrapper single_series_page two_columns">
                 <div className="single_series-content">
                     <div className="gallery">
                        <div className="gallery_left">
                            <div className="gallery_item text" dangerouslySetInnerHTML={{__html: seriesDescription}}></div>
                            {odd}
                        </div>
                        <div className="gallery_right">
                            {even}
                        </div>
                     </div>
                 </div>
    
                 <div className="single_series-nav">
                     { seriesNav }
                 </div>
             </div>
        )
    }
}

export default TwoColumns;