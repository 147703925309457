import React from 'react';
import { withRouter } from 'react-router-dom';
import { LazyImage } from "../../functions/lazyload";
import DotsSpiner from '../dots-spiner/dots-spiner';
import RightColumn from '../series-style/right-column';
import TwoColumns from '../series-style/two-columns';
import Center from '../series-style/center';
import Grid from '../series-style/grid';
import Single from '../series-style/single';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import './single-series.scss';


const SingleSeries = (props) => {
	const { seriesData, seriesTitle, selectSeries, history } = props;

	function findData(arr) {
		return arr.images.map((img, indx) => {
			let originalW = img.original_width / 2;
			let originalH = img.original_height / 2;
			
			return (
				<div className="gallery_item" key={indx}>
					<a href={img.original} data-attribute="SRL">
					{/* <LazyImage
						src={window.innerWidth <= 500 ? img.mobile : img.original}
						alt={img.description}
					/> */}
						<img 
							src={window.innerWidth <= 500 ? img.mobile : img.original} 
							alt={img.description} />
						<p>{img.description}</p>
					</a>
				</div>
			)
		});
	}

	history.listen((location, action) => {
		// console.log("on route change", location.pathname);
		selectSeries(location.pathname);
	})

	function navigation(next, prev) {
		const upArr = (<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="-18 536.5 13 24" enableBackground="new -18 536.5 13 24"><g><polyline fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" points="-17,559.5 -6,548.378 -17,537.5"></polyline></g></svg>);
		const prevArr = (<svg xmlns="http://www.w3.org/2000/svg" width="13" height="24" viewBox="-18 536.5 13 24"><path fill="none" stroke="#000" strokeWidth="2" strokešinecap="round" strokeLinejoin="round" d="M-6 537.5l-11 10.878L-6 559.5"/></svg>);
		const nextArr = (<svg xmlns="http://www.w3.org/2000/svg" width="13" height="24" viewBox="-18 536.5 13 24"><path fill="none" stroke="#000" strokeWidth="2" strokešinecap="round" strokeLinejoin="round" d="M-17 559.5l11-11.122-11-10.878"/></svg>);
		const nextBt = !next ? '' : (
			<span onClick={ () => {
				selectSeries(next.id);
				history.push(`/series/${next.title.split(' ').join('-').toLowerCase()}`)
			}} >{next.title} {nextArr}</span>
		);

		const prevBr = !prev ? '' : (
			<span onClick={ () => {
				selectSeries(prev.id);
				history.push(`/series/${prev.title.split(' ').join('-').toLowerCase()}`)
			}} >{prevArr} {prev.title}</span>
		);

		return (
			<ul className="single_series_nav">
				<li>{prevBr}</li>
				<li className="toTop">
					<span onClick={ () => {window.scrollTo({ top: 0, behavior: 'smooth' })} }>
						{upArr} back to top
					</span>
				</li>
				<li>{nextBt}</li>
			</ul>
		)
	}

	if (!seriesData.series) return <DotsSpiner />;

	const showSeries = findData(seriesData.series);
	const seriesNav = navigation(seriesData.next, seriesData.prev);

	const options = {
		settings: {
			autoplaySpeed: 0,
			disablePanzoom: true,
			slideAnimationType: 'both',
			// disableWheelControls: true
		},
		buttons: {
			showAutoplayButton: false,
			showDownloadButton: false,
			showThumbnailsButton: false
		},
		caption: {
			showCaption: false,
			captionFontSize: '12px'
		},
		thumbnails: {
			showThumbnails: false
		}
	};

	switch(seriesData.series.series_style) {
		case 'Right Column': {
			return seriesData ? (
				<SimpleReactLightbox>
					<SRLWrapper options={options}>
						<RightColumn 
							seriesDescription={seriesData.series.description}
							showSeries={showSeries}
							seriesNav={seriesNav}
						/>
					</SRLWrapper>
				</SimpleReactLightbox>
			) : (
				<DotsSpiner />
			)
		}
		case 'Two Columns': {
			return seriesData ? (
				<SimpleReactLightbox>
					<SRLWrapper options={options}>
						<TwoColumns 
							seriesDescription={seriesData.series.description}
							showSeries={showSeries}
							seriesNav={seriesNav}
						/>
					</SRLWrapper>
				</SimpleReactLightbox>
			) : (
				<DotsSpiner />
			)
		}
		case 'Center': {
			return seriesData ? (
				<SimpleReactLightbox>
					<SRLWrapper options={options}>
						<Center 
							seriesDescription={seriesData.series.description}
							showSeries={showSeries}
							seriesNav={seriesNav}
						/>
					</SRLWrapper>
				</SimpleReactLightbox>
			) : (
				<DotsSpiner />
			)
		}
		case 'Grid': {
			return seriesData ? (
				<SimpleReactLightbox>
					<SRLWrapper options={options}>
						<Grid 
							seriesDescription={seriesData.series.description}
							showSeries={showSeries}
							seriesNav={seriesNav}
						/>
					</SRLWrapper>
				</SimpleReactLightbox>
			) : (
				<DotsSpiner />
			)
		}
		case 'Single': {
			return seriesData ? (
				<SimpleReactLightbox>
					<SRLWrapper options={options}>
						<Single 
							seriesDescription={seriesData.series.description}
							showSeries={showSeries}
							seriesNav={seriesNav}
						/>
					</SRLWrapper>
				</SimpleReactLightbox>
			) : (
				<DotsSpiner />
			)
		}
		default: {
			return seriesData ? (
				<SimpleReactLightbox>
					<SRLWrapper options={options}>
						<RightColumn 
							seriesDescription={seriesData.series.description}
							showSeries={showSeries}
							seriesNav={seriesNav}
						/>
					</SRLWrapper>
				</SimpleReactLightbox>
			) : (
				<DotsSpiner />
			)
		}
	}
}

export default withRouter(SingleSeries);