import React from 'react';
import { NavLink } from 'react-router-dom'
import './header.scss';

const Header = (props) => {
    const { appColorMode } = props;

    function hambutger() {
        document.querySelector('.hamburger').classList.toggle('open');
        document.querySelector('.nav_links').classList.toggle('active');
    }

    function hideNav() {
        document.querySelector('.hamburger').classList.remove('open');
        document.querySelector('.nav_links').classList.remove('active');
    }

    React.useEffect(() => {
        window.addEventListener('resize', hideNav);
        hideNav();
    });
    
    
    return (
        <div className="wrapper">
            <nav>
                <div className="logo">
                    <NavLink to="/" exact activeClassName='active'><span>ALEKSEY ZINCHENKO</span></NavLink>
                </div>

                <div className="hamburger" onClick={hambutger}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                <ul className="nav_links">
                    <li>
                        <NavLink to="/" exact activeClassName='active' onClick={hideNav}>Works</NavLink>
                    </li>
                    <li>
                        <NavLink to="/about" activeClassName='active' onClick={hideNav}>About</NavLink>
                    </li>
                    <li>
                        <NavLink to="/contacts" activeClassName='active' onClick={hideNav}>Contact</NavLink>
                    </li>
                    <li>
                        <div className="switcher" onClick={ () => appColorMode() }>
                            <div className="button"></div>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Header;