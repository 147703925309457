import React from 'react';

const Center = (props) => {
    const {seriesDescription, showSeries, seriesNav } = props;
    return (
        <div className="wrapper single_series_page center">
	 		{/* <h2>seriesTitle = {seriesTitle}</h2> */}
			<div className="text" dangerouslySetInnerHTML={{__html: seriesDescription}}></div>
	 		<div className="single_series-content">
	 			<div className="gallery">
	 				{ showSeries }
	 			</div>
	 		</div>

	 		<div className="single_series-nav">
	 			{ seriesNav }
	 		</div>
	 	</div>
    )
}

export default Center;