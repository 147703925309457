import React from 'react';
import './contact.scss';

export default class Contacts extends React.Component {
    render() {
        return (
            <div className="wrapper contact_page">
                <ul>
                    <li>
                        <a className="contactEmail" href="mailto:info@azinchenko.com">info@azinchenko.com</a>
                    </li>
                    <li>
                        <a className="facebook" href="https://www.facebook.com/aleksey.zinchenko.3">Facebook</a>
                    </li>
                </ul>
            </div>
        )
    }
}