import React from 'react';
import { withRouter } from 'react-router-dom';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import './home.scss';

class Home extends React.Component {
	render() {
		const { seriesData, selectSeries, history } = this.props;

		function loopData(arr) {
			return arr.map(({
				id,
				title,
				year,
				series_preview,
				series_preview_width,
				series_preview_height,
			}) => {
				return (
					<div 
						key={id} 
						className="item"
						// style={{ minWidth: series_preview_width }}
						onClick={ () => {
							const urlTitle = title.toLowerCase().split(' ').join('-');
							selectSeries(id);
							history.push(`/series/${urlTitle}`)
						}}>
						<div className="item_img">
							<img src={series_preview} alt={title} />
						</div>
						<div className="item_title">
							<ul>
								<li><span>{title}</span></li>
								<li>{year}</li>
							</ul>
						</div>
					</div>
				)
			})
		}

		const preview = loopData(seriesData);

		const params = {
			direction: 'horizontal',
			slidesPerView: 'auto',
			// centeredSlides: true,
			spaceBetween: 30,
			mousewheel: true,
			freeMode: true
		  }

		return (
			<div className="home_scroller">
				<Swiper {...params}>
					{preview}
				</Swiper>
			</div>
		)
	}
}

export default withRouter(Home);