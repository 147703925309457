import React from 'react';
import { withRouter } from 'react-router-dom';
import './single.scss';

const Single = (props) => {
    const {showSeries, seriesNav, history } = props; 

    const style = {
        'margin-top': '100px'
    };
    return (
        <React.Fragment>
        {/* onClick={() => history.goBack()} */}
        <div className="singlePicture" style={style}>
            { showSeries }
	 	</div>
        <div className="wrapper single_series_page">
            <div className="single_series-nav">
	 			{ seriesNav }
	 		</div>
        </div>
        </React.Fragment>
    )
}

export default withRouter(Single);