import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import DotsSpiner from '../dots-spiner';
import ScrollToTop from '../../functions/scroll-to-top';
import Home from '../home';
import Header from '../header';
import Contacts from '../contacts';
import About from '../about';
import SingleSeries from '../single-series';

/* window.onscroll = function() {scrollingProgresBar()};

function scrollingProgresBar() {
	let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
	let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
	let scrolled = (winScroll / height) * 100;
	document.getElementById("myBar").style.width = scrolled + "%";
} */

export default class App extends Component {
	state = {
		loading: true,
		series: null,
		selectedSeries: {
			series: null,
			next: null,
			prev: null
		},
		selectedSeriesId: null,
		darkMode: null
	}

	async componentDidMount() {
		console.log('componentDidMount')
		const checkLocal = window.location.href.indexOf("localhost") >= 0 ? 'http://localhost:8888/' : 'https://admin.azinchenko.art/';
		const url = `https://admin.azinchenko.com/wp-content/themes/az/json/series.json`;
		const response = await fetch(url);
		const data = await response.json().catch(error => console.log('error :', error));

		this.setState({
			series: data,
			loading: false
		});

		if (window.location.href.indexOf("series/") >= 0 ) {
			const title = window.location.href.substring(window.location.href.indexOf("series/") + 7);
			const chosenSeries = data.filter(el => el.title.split(' ').join('-').toLowerCase() === title);

			this.selectSeries(chosenSeries[0].id);
		}

		const getStorage = JSON.parse(sessionStorage.getItem('darkMode'));
		if (getStorage) this.setState({ darkMode: getStorage });
		document.body.style.background = this.state.darkMode ? '#000' : '#fff';
	}

	selectSeries = (id) => {
		let newId;
		if (typeof id !== "number") {
			if (id.indexOf("/series/") >= 0) {
				let findId = id.substring(id.indexOf("/series/") + 8);
				newId = this.state.series.filter(el => {
					return el.title.split(' ').join('-').toLowerCase() === findId
				})[0].id;
			} else {
				return;
			}
		} else {
			newId = id;
		}
		const {series} = this.state;
		const indx = series.findIndex(i => i.id === newId);
		const next = (indx + 1 > series.length - 1) ? null : series[indx + 1];
		const prev = (indx - 1 < 0) ? null : series[indx - 1];

		this.setState({
			selectedSeriesId: newId,
			selectedSeries: {
				series: series.filter(e => e.id === newId)[0],
				next,
				prev
			}
		});
	}

	switchToDarkMode = () => {
		const val = !this.state.darkMode;
		sessionStorage.setItem('darkMode', val);
		this.setState({ darkMode: val });

		document.body.style.background = val ? '#000' : '#fff';
	}


	render() {
		const { loading, series, selectedSeries, darkMode } = this.state;

		// if (loading) return 'loading...';
		if (!series) return (
			<DotsSpiner />
		);

		return (
			<div className={ `app${ darkMode ? ' dark' : '' }` }>
				{/* <div className="progress-container">
					<div className="progress-bar" id="myBar"></div>
				</div> */}
				
				<Router>
					<Header appColorMode={ this.switchToDarkMode } />
					<ScrollToTop />
					<Route 
						path="/"
						exact
						render={ () => <Home seriesData={ series } selectSeries={ this.selectSeries } /> }
					/>

					<Route path="/contacts" exact component={Contacts}/>

					<Route path="/about" exact component={About}/>

					<Route 
						path="/series/:title"
						render={ ({ match }) => {
							return <SingleSeries 
										seriesData={ selectedSeries }
										seriesTitle={ match.params.title } 
										selectSeries={ this.selectSeries }
									/> 
						}}
					/>
				</Router>
			</div>
		)
	}
}