import React from 'react';
import './dots-spiner.scss';

const DotsSpiner = () => {
    return (
        <div id="page-preloader">
            <div className="browser-screen-loading-content">
                <div className="loading-dots dark-gray">
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                </div>
            </div>
        </div>
    )
}

export default DotsSpiner;